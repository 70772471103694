import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22b7989e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_uploader = _resolveComponent("van-uploader")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_cell_group, {
      inset: "",
      class: "list",
      title: _ctx.isShowTitle ? '图片' : '',
      border: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_uploader, {
          modelValue: _ctx.fileList,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fileList) = $event)),
          onDelete: _ctx.onDelete,
          "upload-icon": _ctx.uploadIcon,
          "max-count": _ctx.maxCount,
          "after-read": _ctx.afterRead,
          multiple: _ctx.multiple,
          accept: _ctx.acceptType,
          "preview-image": ""
        }, null, 8, ["modelValue", "onDelete", "upload-icon", "max-count", "after-read", "multiple", "accept"])
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}