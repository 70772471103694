import { cateringUploadsHide } from '@/utils/fetch'

//多上传文件
export function uploadFile(param: FormData) {
  const url = '/api/pub/upload/multiUpload'
  return cateringUploadsHide.post(url, param)
}

//单上传文件
export function uploadFile1(param: FormData) {
  const url = '/api/pub/upload/oneFileUpload'
  return cateringUploadsHide.post(url, param)
}
