
import { uploadFile } from '@/apis/public/file'
import { ref, watch, PropType, onActivated, toRef, defineComponent } from 'vue'
import lrz from 'lrz'
import { uploadFileItem } from '@/apis/base'
import { isArray } from 'lodash'
import _ from 'lodash'

export default defineComponent({
  props: {
    //父级传递过来已上传图片
    modelValue: {
      type: Array as PropType<Array<uploadFileItem>>,
      default: []
    },
    //默认允许上传的类型
    acceptType: {
      type: String,
      default: 'image/*'
    },
    //最多上传的图片数量
    maxCount: {
      type: Number,
      default: 5 // 默认5张
    },
    //上传图片的默认icon
    uploadIcon: {
      type: String,
      default: 'photograph'
    },
    // 是否开启图片多选
    multiple: {
      type: Boolean,
      default: true
    },
    isShowTitle: {
      type: Boolean,
      default: true
    }
  },

  setup(props, { emit }) {
    const uploadList = ref<Array<uploadFileItem>>([]) // 上传的图片
    const fileList = ref<Array<any>>([]) // 展示的图片

    watch(props, v => {
      console.log('props =====>', v)
      let ps = v.modelValue as Array<uploadFileItem>
      if (ps.length == 0) {
        console.log('props=====>2')
        initDatas()
      }
      //
      // 获取父组件传递过来的图片，进行展示
      // fileList为空时则为首次加载，仅首次加载时展示父组件传递过来的图片

      if (ps.length > 0 && fileList.value.length == 0) {
        console.log('props=====>3')
        ps.forEach(pv => {
          const imgTmp: any = { url: pv.domain_path, isImage: true }
          fileList.value.push(imgTmp)
        })

        // 赋值给uploadList
        uploadList.value = ps
      }
    })

    watch(uploadList, (newVal, oldVal) => {
      emit('update:modelValue', newVal)
    })

    onActivated(() => {
      // 初始化数据
      initDatas()
    })

    const initDatas = () => {
      if (fileList.value.length > 0) {
        fileList.value = []
      }
      if (uploadList.value.length > 0) {
        uploadList.value = []
      }
    }

    //上传文件前
    const beforeRead = () => {
      console.log('上传文件前')
    }

    //删除图片
    const onDelete = (item: any, val: any) => {
      console.log('onDelete ===>', item)
      uploadList.value.splice(val.index, 1)
      // uploadList.value = uploadList.value.filter(v => {
      //   // 根据filename进行比对删除，适用于新上传的图片
      //   if (v.file_name) {
      //     return v.file_name != item.file?.name
      //   }
      //   // 根据url进行删除，适用于props带进来的图片
      //   if (v.domain_path) {
      //     return v.domain_path != item.url
      //   }
      //   return false
      // })
    }

    //上传文件
    const afterRead = (file: any) => {
      console.log('afterRead.file ===>', file)

      let fs = []
      if (isArray(file)) {
        fs = file
      } else {
        fs.push(file)
      }

      // 循环上传
      fs.forEach((f: any) => {
        f.status = 'uploading'
        f.message = '上传中...'

        lrz(f.file, { quality: 0.7, width: 1000 }).then((fr: any) => {
          const fileName = f.file.name
          const param = new FormData()
          param.append('file', fr.file, fileName) //原文件名字也传递给后端，后台是个坑货，通过名字来判断扩展名

          uploadFile(param).then((res: any) => {
            if (res.code == 200) {
              const tmp = res.data as uploadFileItem
              tmp.file_name = fileName
              uploadList.value = uploadList.value.concat(tmp)

              f.status = 'done'
              f.message = '上传成功'
            } else {
              f.status = 'failed'
              f.message = '上传失败'
            }
          })
        })
      })
    }

    return {
      uploadList,
      fileList,
      beforeRead,
      afterRead,
      onDelete
    }
  }
})
